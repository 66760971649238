import { useEffect, useState } from "react";
import {
	FaPaperclip,
	FaPaperPlane,
	FaSearchengin,
	FaServicestack,
	FaXmark,
} from "react-icons/fa6";
import FileUploader from "./FileUploader";
import MediaViewer from "./MediaViewer";
import Overlay from "./Overlay";
import { FaSearch } from "react-icons/fa";
function Messanger(props) {
	const [message, setMessage] = useState("");
	const [isSearching, setIsSearching] = useState(false);
	const [searchingText, setSearchingText] = useState("");
	const [searchT, setSearchT] = useState(null);
	function sendMessage() {
		props.socket.emit("sendMessage", {
			to: props.activeDialog,
			message: message,
		});
		setMessage("");
	}

	useEffect(() => {
		clearInterval(searchT);

		var t = setTimeout(() => {
			props.searchMessages(searchingText);
		}, 1000);

		setSearchT(t);
	}, [searchingText]);

	useEffect(() => {
		setMessage("");
	}, [props.activeDialog]);

	const now =
		new Date().setUTCHours(new Date().getUTCHours() + 3) && Date.now();

	function getFormattedDateTime(date, type) {
		const now = new Date(date);

		const day = String(now.getDate()).padStart(2, "0");
		const month = String(now.getMonth() + 1).padStart(2, "0");
		const hours = String(now.getHours()).padStart(2, "0");
		const minutes = String(now.getMinutes()).padStart(2, "0");

		return type && type == 1 ? day + "." + month : hours + ":" + minutes;
	}

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			if (e.ctrlKey) {
				e.preventDefault();
				setMessage((message) => message + "\n");
			} else {
				e.preventDefault();
				sendMessage();
			}
		}
	};

	function gotoMessage(id) {
		console.log(id);
		setIsSearching(false);
		props.setSearchedMessages([]);
		setSearchingText("");
		props.socket.emit("gotoMessage", { to: id, id: props.activeDialog });
	}

	return (
		<div
			className={
				props.activeDialog !== 0 || props.isInfoOpened
					? "Messanger"
					: "Messanger  Hidden"
			}
		>
			{props.isInfoOpened ? (
				<Overlay
					getPaymentInfo={props.getPaymentInfo}
					orderInfo={props.orderInfo}
					setIsInfoOpened={props.setIsInfoOpened}
					socket={props.socket}
					triggerNotification={props.triggerNotification}
					order={props.order}
				/>
			) : null}

			{props.activeDialog !== 0 ? (
				<>
					<div className="Header">
						<FaXmark
							className="close"
							onClick={() => {
								props.setActiveDialog(0);
							}}
						/>
						<p className="Avatar">
							{props.dialogs
								?.find((x) => x.tgId == props.activeDialog)
								?.name.slice(0, 1)}
						</p>
						<p className="Nickname">
							{props.dialogs?.find((x) => x.tgId == props.activeDialog)?.name}
						</p>
						<FaSearch
							onClick={() => {
								setIsSearching(true);
							}}
							className="search"
						/>
					</div>
					{isSearching ? (
						<>
							<div className="Container">
								{props.searchedMessages.map((x, key) => (
									<>
										{(now - x.date < 86400000 &&
											now - props.messages[key - 1]?.date > 86400000) ||
										!props.messages[key - 1] ? (
											<p key={key + 9999} className="NewDay">
												{getFormattedDateTime(x.date, 1)}
											</p>
										) : null}

										{x.source === "PANEL" && x.panelUserId === -1 ? (
											<p style={{ textAlign: "center" }}>{x.text}</p>
										) : (
											<div
												onClick={() => {
													gotoMessage(x.id);
												}}
												key={key}
												className={
													x.source === "PANEL" ? "Message My" : "Message"
												}
											>
												{x.source === "PANEL" ? (
													<p className="MessageFrom">
														{props.supports.find((s) => s.id === x.panelUserId)
															? props.supports.find(
																	(s) => s.id === x.panelUserId
															  ).displayName
															: "Support"}
													</p>
												) : null}
												<p>{x.text}</p>
												{x.attachLink ? (
													<div className="Attach">
														<MediaViewer
															src={"/api/media/" + x.attachLink}
															type={
																x.attachLink.indexOf(".jpg") > 0
																	? "image"
																	: x.attachLink.indexOf(".mp4") > 0
																	? "video"
																	: "file"
															}
														/>
													</div>
												) : null}
												<p className="When">{getFormattedDateTime(x.date)}</p>
											</div>
										)}
									</>
								))}
							</div>
							<div className="SendMessage">
								<textarea
									value={searchingText}
									onChange={(e) => setSearchingText(e.target.value)}
									placeholder="Поиск"
								/>
							</div>
						</>
					) : (
						<>
							<div className="Container">
								{props.messages[props.messages?.length - 1]?.isFirst ? null : (
									<button
										style={{ width: "150px", alignSelf: "center" }}
										onClick={() => {
											props.socket.emit("loadMessages", {
												id: props.activeDialog,
												from: props.messages[0].id,
											});
										}}
									>
										Загрузить ещё
									</button>
								)}

								{props.messages.map((x, key) => (
									<>
										{(now - x.date < 86400000 &&
											now - props.messages[key - 1]?.date > 86400000) ||
										!props.messages[key - 1] ? (
											<p key={key + 9999} className="NewDay">
												{getFormattedDateTime(x.date, 1)}
											</p>
										) : null}

										{x.source === "PANEL" && x.panelUserId === -1 ? (
											<p style={{ textAlign: "center" }}>{x.text}</p>
										) : (
											<div
												key={key}
												className={
													x.source === "PANEL" ? "Message My" : "Message"
												}
											>
												{x.source === "PANEL" ? (
													<p className="MessageFrom">
														{props.supports.find((s) => s.id === x.panelUserId)
															? props.supports.find(
																	(s) => s.id === x.panelUserId
															  ).displayName
															: "Support"}
													</p>
												) : null}
												<p>{x.text}</p>
												{x.attachLink ? (
													<div className="Attach">
														<MediaViewer
															src={"/api/media/" + x.attachLink}
															type={
																x.attachLink.indexOf(".jpg") > 0
																	? "image"
																	: x.attachLink.indexOf(".mp4") > 0
																	? "video"
																	: "file"
															}
														/>
													</div>
												) : null}
												<p className="When">{getFormattedDateTime(x.date)}</p>
											</div>
										)}
									</>
								))}
							</div>
							<div className="SendMessage">
								<FileUploader
									activeDialog={props.activeDialog}
									socket={props.socket}
								/>
								<textarea
									onKeyDown={(e) => handleKeyDown(e)}
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									placeholder="Сообщение"
								/>
								<FaPaperPlane
									onClick={() => {
										sendMessage();
									}}
								/>
							</div>
						</>
					)}
				</>
			) : null}
		</div>
	);
}

export default Messanger;
