import { act, useEffect, useState, useRef } from "react";
import DialogList from "../components/DialogList";
import DraggableWindow from "../components/DraggableWindow";
import Messanger from "../components/Messanger";
import "../css/Dialogs.css";
import { io } from "socket.io-client";
import {
	FaUserLarge,
	FaMessage,
	FaGear,
	FaPaperPlane,
	FaCircleNotch,
} from "react-icons/fa6";
import NotificationSystem from "../components/Notifications";
const socket = io("/", {
	transports: ["polling", "websocket"],
	autoConnect: true,
	closeOnBeforeunload: true,
	reconnection: true,
});

function inViewport(element) {
	if (!element) return false;
	if (1 !== element.nodeType) return false;

	var html = document.documentElement;
	var rect = element.getBoundingClientRect();

	return (
		!!rect &&
		rect.bottom >= 0 &&
		rect.right >= 0 &&
		rect.left <= html.clientWidth &&
		rect.top <= html.clientHeight
	);
}

function Dialogs(props) {
	const audioNotification = useRef();
	const notificationSystemRef = useRef();

	const triggerNotification = (t) => {
		if (notificationSystemRef.current) {
			notificationSystemRef.current.addNotification(t);
		}
	};
	const [orderInfo, setOrderInfo] = useState({});
	const [isInfoOpened, setIsInfoOpened] = useState(false);
	const [order, setOrder] = useState("");

	const [supports, setSupports] = useState([]);

	const [messages, setMessages] = useState([]);
	const [activeDialog, setActiveDialog] = useState(0);
	const [dialogs, setDialogs] = useState([]);

	const [searchedMessages, setSearchedMessages] = useState([]);

	useEffect(() => {
		setMessages([]);
		socket.emit("subNewDialog", { id: activeDialog });
		socket.emit("loadMessages", { id: activeDialog });
	}, [activeDialog]);

	useEffect(() => {
		if (messages.length <= 100) {
			setTimeout(() => {
				try {
					document
						.querySelector(
							"#root > div > div > div > div > div > div.Messanger > div.Container"
						)
						.scrollTo({ top: 9999, left: 0, behavior: "smooth" });
				} catch {}
			}, 100);
		}
	}, [messages]);

	const onNewMsg = (msg) => {
		setActiveDialog((activeDialog) => {
			if (msg.tgUserId === activeDialog) {
				setMessages((messages) => [...messages, msg]);
			}
			return activeDialog;
		});
		setDialogs(
			(dialogs) => (
				(Array.from(dialogs).find((x) => x.tgId === msg.tgUserId).lastMessage =
					msg),
				Array.from(dialogs)
			)
		); // перенести отсюда в отдельный эвенет нью месседжа) чтобы было глобал) или оставить это плюсом обнова всех диалогов каждые +- 5-10сек
		setTimeout(() => {
			if (
				inViewport(
					document.querySelector(
						"#root > div > div > div > div > div > div.Messanger > div.Container > div:last-of-type"
					)
				)
			)
				document
					.querySelector(
						"#root > div > div > div > div > div > div.Messanger > div.Container"
					)
					.scrollTo({
						top: document.querySelector(
							"#root > div > div > div > div > div > div.Messanger > div.Container"
						).scrollHeight,
						left: 0,
						behavior: "smooth",
					});
		}, 100);
	};

	function searchMessages(text) {
		socket.emit("searchMessages", { text: text, id: activeDialog });
	}

	function handleConnect() {
		socket.on("loadSupports", (response) => {
			setSupports(response);
		});

		socket.on("loadDialogs", (response) => {
			setDialogs(response);
		});

		socket.on("notify", (response) => {
			if (document.hidden) audioNotification.current.play();
		});

		socket.on("loadMessages", (response) => {


			if (response.length === 0) {
				setMessages((messages) =>
					messages.map((msg, i) =>
						i === messages.length - 1 ? { ...msg, isFirst: true } : msg
					)
				);
			} else {
				setMessages((messages) => [...response, ...messages]);
			}
		});

		socket.on("newMsg", onNewMsg);
	}

	useEffect(() => {
		// socket.connect();

		socket.on("connect", () => {
			handleConnect();

			socket.emit("loadDialogs");
			socket.emit("loadSupports");
			socket.emit("subDialogsUpdate");
		});

		socket.on("searchMessages", (props) => {
			setSearchedMessages(props);
		})

		socket.on("scrollTo", (props) => {
			try {
				
			} catch {

			}
		} )

		socket.on("disconnect", () => {
			window.location.reload();
		});

		window.addEventListener("offline", () => {
			socket.disconnect();
		});

		audioNotification.current.volume = 0.1;

		return () => {
			socket.removeAllListeners();
		};
	}, []);

	function getPaymentInfo() {
		fetch(
			`https://yellowchanger.com/api/info/get_exch_info.php?exch_id=${order}`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
			}
		)
			.then(async (res) => {
				if (res.status !== 200) throw true;
				return res.json();
			})
			.then((res) => {
				setOrderInfo(res);
				setIsInfoOpened(true);
			})
			.catch((e) => {
				triggerNotification("Произошла ошибка при получении информации!");
				console.log("error");
			});
	}

	return (
		<>

			{props.user.loaded && !props.user.error ? (
				<div className="Dialogs">
					<DialogList
						setActiveDialog={setActiveDialog}
						activeDialog={activeDialog}
						dialogs={dialogs}
						socket={socket}
						supports={supports}
						isInfoOpened={isInfoOpened}
					/>

					<Messanger
						searchedMessages={searchedMessages}
						getPaymentInfo={getPaymentInfo}
						triggerNotification={triggerNotification}
						user={props.user}
						order={order}
						searchMessages={searchMessages}
						setSearchedMessages={setSearchedMessages}
						messages={messages}
						setActiveDialog={setActiveDialog}
						activeDialog={activeDialog}
						dialogs={dialogs}
						socket={socket}
						supports={supports}
						isInfoOpened={isInfoOpened}
						setIsInfoOpened={setIsInfoOpened}
						orderInfo={orderInfo}
					/>
					{!isInfoOpened ? (
						<DraggableWindow>
							<div className="Form">
								<input
									value={order}
									onChange={(e) => {
										setOrder(e.target.value);
									}}
									placeholder="Номер сделки"
								/>
								<FaPaperPlane
									onClick={() => {
										getPaymentInfo();
									}}
								/>
							</div>
						</DraggableWindow>
					) : null}
					<NotificationSystem ref={notificationSystemRef} />
				</div>
			) : null}
			<audio
				ref={audioNotification}
				src="/ding.mp3"
				controls={false}
				style={{ position: "absolute", zIndex: -1, opacity: "0.1" }}
			/>
		</>
	);
}

export default Dialogs;
